/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import camelcaseKeys from 'camelcase-keys';
import classNames from 'classnames';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AssessmentLogoSpecificIcon from 'ui-lib-12traits/src/Icons/svg/common/12traits360-logo-specific.svg?component';
import PreferenceAssessment from 'ui-lib-12traits/src/Icons/svg/common/preferences-assessment.svg?component';
import AsteriskIcon from 'ui-lib-12traits/src/Icons/svg/common/asterisk.svg?component';

import InfoIcon from 'ui-lib-12traits/src/Icons/svg/common/info.svg?component';
import { GlobalButton, Overlay, Tooltip } from 'ui-lib-12traits/src/index';

import { useDispatch } from 'react-redux';
import LockIcon from 'ui-lib-12traits/src/Icons/svg/common/lock.svg?component';
import { generatePath, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@/hooks/useApp';
import { useI18n } from '@/i18n';
import { Template, Status, StaticValues } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { newAssessmentAutomationPath } from '@/route/paths';
import { fetchAssessments, fetchExistingGames } from '@/services/Api/AssessmentSurveyService';
import { fetchKnownGames } from '@/services/Api/GamesService';

import { ContentTypeEnum } from '@/redux/game/types';

import { setGame } from '@/redux/game/actions';
import { selectGames } from '@/redux/games/selectors';
import StepContainer from '../StepContainer';

import AudienceStepI18n from './AudienceStep.i18n';
import styles from './AudienceStep.module.scss';
import SearchInput from './SearchInput';
import GamingIcon from './svg/gaming.svg?component';
import NonGamingIcon from './svg/global-network.svg?component';
import HealthIcon from './svg/healthcare.svg?component';

type Props = {
  onNext: (values: StaticValues) => void;
  values?: StaticValues;
  setActiveStep?: (t: Template) => void;
  setHoveredStep?: (t: Template) => void;
  title: string;
  baseStepValues?: StaticValues | null;
  isActive: boolean;
  setOpenModal?: (val: boolean) => void;
  withNumber?: boolean;
  style?: any;
  onClose: () => void;
  template: Template;
  status?: Status;
  updateStepValues: (values: StaticValues, t: Template) => void;
  setErrorCount: any;
  errorCount: any;
  deleteAssessment: () => void;
  saveAndClose: () => void;
};

const OFFSET_FROM_1970 = 1000;

export type GameOption = {
  id: string;
  label: string;
};

function AudienceStep({
  onNext,
  isActive,
  setActiveStep,
  values = {} as StaticValues,
  title,
  withNumber = true,
  template,
  onClose,
  status,
  updateStepValues,
  setErrorCount,
  saveAndClose,
  deleteAssessment,
  errorCount,
  ...step
}: Props) {
  const [game, setCurrentGame] = useState('');
  const [audience, setAudience] = useState('');
  const [inputVal, setInputVal] = useState('');
  const errorCountRef = useRef(errorCount);
  const [audienceType, setAudienceType] = useState('');
  const buttonsWrapperRef = useRef<any>(null);
  const [assessmentType, setAssessmentType] = useState('');
  const dashboards = useAppSelector(selectGames);
  const dispatch = useDispatch();
  const i18n = useI18n(AudienceStepI18n);
  const locked = status === 'completed';
  const [showModal, setShowModal] = useState(false);

  const { data: assessments } = useQuery({
    queryKey: ['assessments'],
    queryFn: fetchAssessments
  });

  const ASSESSMENT_TYPES_LIST = [
    {
      id: '360',
      name: 'Psychological traits',
      icon: <AssessmentLogoSpecificIcon width={98} />,
      subtitle: `Reassess ${i18n.actor(audienceType)} psychology post turnover or expansion.`,
      reassessmentAvailable: true
    },
    {
      id: 'preference',
      name: 'Preferences',
      subtitle: `Assess ${i18n.actor(
        audienceType
      )} preferences for game features, influencers, and much more.`,
      icon: <PreferenceAssessment width={98} />,
      reassessmentAvailable: false,
      new: true
    }
  ];

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }

  const formatAssessedAt = useCallback(
    (option: { id: string; label: string; assessedAt: number }) => ({
      ...option,
      assessedAt: i18n.formatters.time(
        dayjs(option.assessedAt * OFFSET_FROM_1970).format('YYYY-MM-DD')
      )
    }),
    [i18n]
  );

  const {
    data: { options = [] },
    existing,
    refetch
  } = useGetGames({
    onSuccess: (res: any) => {
      const [existingGames, knownGames] = res as [
        Array<{ id: string; name: string; assessedAt: number }>,
        Array<{ id: string; value: string }>
      ];

      const eg = pipe(
        map(({ name, ...rest }: { name: string }) => ({ label: name, ...rest })),
        map(formatAssessedAt),
        sortAlphabeticallyWithNumbers('label')
      )(existingGames);

      const kg = pipe(
        map(({ id, value }: { id: string; value: string }) => ({ id, label: value })),
        ifElse(
          (_: Array<{ id: string; label: string; assessedAt: number }>) => inputVal === '',
          sortAlphabeticallyWithNumbers('label'),
          (x: Array<{ id: string; label: string; assessedAt: number }>) => x
        )
      )(knownGames);

      return removeDuplicates('label')(eg.concat(kg));
    }
  });

  const isAssessed = game ? !!(existing ?? []).find(g => g.name === game) : false;
  const existingGame = (existing ?? []).find(g => g.name === game);
  const slug = (dashboards ?? []).find(g => g.game_profile_id === existingGame?.id)?.slug;
  const showPreferencesLabel = !(existing ?? []).every(
    game =>
      (assessments?.data ?? []).find(a => a.projectName === game.name)?.assessmentType ===
      'preference'
  );
  const hasPreferences =
    assessments?.data.find(a => a.projectName === game)?.assessmentType === 'preference';

  const AUDIENCE_TYPES_CONFIG = useMemo(
    () => [
      { value: ContentTypeEnum.Product, Icon: NonGamingIcon, title: i18n.actors.product },
      {
        value: ContentTypeEnum.Gaming,
        Icon: GamingIcon,
        title: i18n.actors.gaming,
        renderLabel:
          audienceType === ContentTypeEnum.Gaming ||
          (audienceType !== ContentTypeEnum.Gaming && !showPreferencesLabel)
            ? null
            : () => (
                  <div className={styles.label_new}>
                    <AsteriskIcon width={14} /> <span>Preferences now available</span>
                  </div>
                )
      },
      { value: ContentTypeEnum.Medical, Icon: HealthIcon, title: i18n.actors.medical }
    ],
    [i18n, audienceType, showPreferencesLabel]
  );

  useEffect(() => {
    if (isAssessed && slug) {
      dispatch(setGame(slug));
    }
  }, [isAssessed, slug]);

  const debouncedFetchKnownGames = useRef(
    debounce((val: string, audienceType: string) => {
      try {
        refetch(val, audienceType);
      } catch (err) {
        console.error(err);
      }
    }, 300)
  );

  useEffect(() => {
    debouncedFetchKnownGames.current(inputVal, audienceType);
  }, [inputVal, audienceType]);

  useEffect(() => {
    if (status === 'completed') {
      const { audienceName, projectName, contentType, assessmentType } = camelcaseKeys(values);
      setCurrentGame(projectName);
      setInputVal(projectName);
      setAudience(audienceName);
      setAudienceType(contentType);
      setAssessmentType(assessmentType);
    }
  }, [status]);

  useEffect(() => {
    if (status === 'completed') {
      setErrorCount({ ...errorCount, [template]: !!inputVal });
    }
  }, [inputVal, errorCountRef.current]);

  return (
    <>
      <StepContainer
        id="1"
        title={title}
        onClose={onClose}
        template={template}
        isExpanded={isActive}
        testId="audience-step"
        invalid={errorCount?.[template] === false}
        withNumber={withNumber}
        onExpand={setActiveStep}
        style={{ marginTop: '84px', marginBottom: withNumber ? '15px' : '150px' }}
        collapsedElement={
          <div className={styles.card__value}>
            <span>{audience}</span>
          </div>
        }
        locked={locked}
      >
        <div className={styles.step}>
          <h1 data-testid="title" className={styles.title}>
            {i18n.title}
          </h1>

          <p className={styles.text}>
            {i18n.description.fragments.first} <br />
            {process.env.SHOW_USER_PREFERENCES !== 'true' && (
              <span>
                {i18n.description.fragments.second}{' '}
                <AssessmentLogoSpecificIcon width={87} height={23} />
                {i18n.description.fragments.third}
              </span>
            )}
          </p>

          <h2 className={styles.form__title}>
            {i18n.audience.title}{' '}
            <Tooltip
              maxWidth={214}
              padding={14}
              borderRadius={8}
              zIndex={3}
              className={styles.tooltip}
              content={i18n.audience.tooltip}
            >
              <span>
                <InfoIcon tabIndex={0} />
              </span>
            </Tooltip>
          </h2>

          <section
            ref={buttonsWrapperRef}
            className={classNames(styles['audience-type'], {
              [styles['audience-type--checked']]: audienceType.length > 0
            })}
          >
            {AUDIENCE_TYPES_CONFIG.map(({ value, Icon, title, renderLabel }) => (
              <label htmlFor={value} key={value}>
                <Icon width={48} />
                <input
                  type="radio"
                  name="audience"
                  checked={value === audienceType}
                  id={value}
                  onChange={() => (locked ? setShowModal(true) : setAudienceType(value))}
                />
                <span>{title}</span>
                {renderLabel && renderLabel()}
              </label>
            ))}
          </section>
          {audienceType && (
            <>
              <h2 className={styles.form__title}>{i18n.target.title(audienceType)}</h2>
              <SearchInput
                option={{ label: inputVal, id: '' }}
                options={options.map(o => ({
                  ...o,
                  hasPreferences:
                    assessments?.data.find(a => a.projectName === o.label)?.assessmentType ===
                    'preference'
                }))}
                splitBy="assessedAt"
                contentType={audienceType}
                refs={[buttonsWrapperRef]}
                placeholder={i18n.target.placeholder(audienceType)}
                onChange={setInputVal}
                disabled={locked}
                onClick={() => (locked ? setShowModal(true) : null)}
                onSelect={(option: GameOption) => {
                  setInputVal(option.label);

                  setCurrentGame(option.label);
                  setAudience(`${option.label}: ${dayjs().format('YYYY-MM-DD')}`);

                  if (isActive) {
                    updateStepValues(
                      {
                        id: step?.id,
                        assessmentType: isAssessed ? assessmentType : '360',
                        contentType: audienceType,
                        projectName: option.label,
                        audienceName: `${option.label}: ${dayjs().format('YYYY-MM-DD')}`
                      },
                      template
                    );
                  }
                }}
              />
            </>
          )}

          {inputVal.length > 0 &&
            isAssessed &&
            audienceType === ContentTypeEnum.Gaming &&
            process.env.SHOW_USER_PREFERENCES === 'true' && (
              <>
                <h2 className={styles.form__title}>{i18n.type.title(audienceType)}</h2>

                <ul className={styles.list}>
                  {ASSESSMENT_TYPES_LIST.map(item => (
                    <li key={item.id}>
                      <button
                        type="button"
                        className={classNames(styles.list_item, {
                          [styles.list_item_selected]: assessmentType === item.id
                        })}
                        onClick={() => (locked ? setShowModal(true) : setAssessmentType(item.id))}
                      >
                        {item.icon}
                        <div className={styles.list_item_label}>
                          <p>
                            <b className={styles.game_name}>{item.name}</b>{' '}
                            <span>{item.reassessmentAvailable ? '(Reassessment)' : ''}</span>
                            {!hasPreferences && item.new && (
                              <span className={styles.label_availability}>
                                <AsteriskIcon width={12} /> Now available
                              </span>
                            )}
                          </p>
                          <p className={styles.list_item_subtitle}>
                            <span>{item.subtitle}</span>
                          </p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}

          {(inputVal.length > 0 && !isAssessed) ||
          (isAssessed &&
            inputVal.length > 0 &&
            ((assessmentType && audienceType === ContentTypeEnum.Gaming) ||
              audienceType !== ContentTypeEnum.Gaming)) ? (
            <div className={styles.footer}>
              <GlobalButton
                className={styles['btn-start']}
                testid="next-btn"
                title={i18n.actions.proceed(status === 'completed')}
                onClick={() =>
                  onNext({
                    assessmentType: isAssessed ? assessmentType : '360',
                    contentType: audienceType,
                    projectName: game,
                    audienceName: audience
                  })
                }
              />
              {locked && (
                <div className={styles.locked_label}>
                  <LockIcon width={12} />{' '}
                  <span>
                    To switch your audience or assessment type, please{' '}
                    <button type="button" onClick={() => setShowModal(true)}>
                      start a new request.
                    </button>
                  </span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </StepContainer>
      {showModal && (
        <CloseModal
          game={game}
          onDelete={deleteAssessment}
          onSave={saveAndClose}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default AudienceStep;

function CloseModal({ onClose, game, onSave, onDelete }) {
  const [checked, setChecked] = useState(true);
  const history = useHistory();
  const openAssessment = (): void => {
    history.push(`${generatePath(newAssessmentAutomationPath)}#new`);
  };

  return (
    <Overlay
      contentClassName={styles.modal}
      testId="close-assessment-modal"
      centered
      onClose={onClose}
      width={482}
    >
      <div className={styles.modal_wrapper}>
        <h1 className={styles.modal_title}>Start new assessment request?</h1>
        <p className={styles.modal_text}>
          To switch your audience or assessment type, please start a new request.{' '}
        </p>
        <div className={styles.input_wrapper}>
          <input type="checkbox" onChange={e => setChecked(e.target.checked)} checked={checked} />
          Save this request for {game}.
        </div>
        <div className={styles.modal_buttons}>
          <GlobalButton
            className={styles.button_start}
            title="Start new assessment request"
            onClick={() => {
              if (checked) onSave();
              if (!checked) onDelete();
              onClose();
              openAssessment();
            }}
          />

          <GlobalButton
            title="Cancel"
            onClick={onClose}
            type="secondary"
            className={styles['button-cancel']}
          />
        </div>
      </div>
    </Overlay>
  );
}

function useGetGames(options: any = {}) {
  const [status, setStatus] = useState<'success' | 'rejected' | 'idle' | 'loading'>('idle');

  const [known, setKnown] = useState<Array<any>>([]);
  const [existing, setExisting] = useState<Array<any>>([]);
  const initialExistingGames = useRef<Array<any>>([]);

  const [res, setRes] = useState(existing.concat(known));

  async function getGames(searchTerm: string, audienceType: string = '') {
    searchTerm = searchTerm.trim();

    setStatus('loading');

    try {
      const resK =
        searchTerm.length && audienceType === ContentTypeEnum.Gaming
          ? await fetchKnownGames(searchTerm, 'game')
          : { data: [] };
      const resE = await fetchExistingGames();

      setExisting(resE.data);
      initialExistingGames.current = resE.data;

      setStatus('success');

      setKnown(resK.data);

      const filtered = initialExistingGames.current.filter(game =>
        game.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setExisting(searchTerm === '' ? initialExistingGames.current : filtered);

      if (options?.onSuccess) {
        setRes(
          options.onSuccess([
            searchTerm === '' ? initialExistingGames.current : filtered,
            resK.data
          ])
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getGames('');
  }, []);

  return {
    data: {
      options: res
    },
    status,
    existing,
    refetch: getGames
  };
}

function removeDuplicates<T extends { [key: string]: any }>(key: string) {
  return (list: T[]) => {
    const set = new Set();
    return list.filter(x => {
      if (!set.has(x[key])) {
        set.add(x[key]);
        return true;
      }

      return false;
    });
  };
}

export function ifElse(conditionFn: any, trueFn: any, falseFn: any) {
  return (...args: any) => {
    if (conditionFn(...args)) {
      return trueFn(...args);
    }

    return falseFn(...args);
  };
}

export function pipe<T>(...fns: Function[]) {
  return (x: T) => fns.reduce((acc, fn) => fn(acc), x);
}

export function map<T, G>(fn: (val: T, i: number) => G) {
  return (list: Array<T>) => list.map(fn);
}

function sortAlphabeticallyWithNumbers(sortingKey: string | Array<any>) {
  if (Array.isArray(sortingKey)) {
    return sortWithoutKey(sortingKey);
  }
  return sortWithKey(sortingKey);

  function sortWithKey(sk: string) {
    return <T extends { [sk: string]: string }>(list: Array<T>) => {
      list.sort((a, b) => {
        if (startsWithNumber(a[sk]) && !startsWithNumber(b[sk])) return 1;
        if (!startsWithNumber(a[sk]) && startsWithNumber(b[sk])) return -1;
        return a[sk].localeCompare(b[sk]);
      });

      return list;

      function startsWithNumber(str: string) {
        if (str.length === 0) {
          return false;
        }

        return str[0].match(/^\d$/);
      }
    };
  }

  function sortWithoutKey(list: Array<string>) {
    list.sort((a, b) => {
      if (startsWithNumber(a) && !startsWithNumber(b)) return 1;
      if (!startsWithNumber(a) && startsWithNumber(b)) return -1;
      return a.localeCompare(b);
    });

    return list;

    function startsWithNumber(str: string) {
      if (str.length === 0) {
        return false;
      }

      return str[0].match(/^\d$/);
    }
  }
}
